import { setVersion } from './config';
import { pruneExpiredKeys } from './local-storage';
import { setupExtensionListeners } from './message-listeners';
import { checkAutoScrapeProgress, setAppState } from './page-data';
import { setupAutoTagInputFocus } from './search-tag';
import { initializeMixpanel } from './track';

setVersion(window.location.pathname);
initializeMixpanel();
setupExtensionListeners();
setAppState('Pending');
pruneExpiredKeys();
checkAutoScrapeProgress();
setupAutoTagInputFocus();
