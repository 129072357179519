// cspell:ignore ddifhkkdlhheplpcknmceknjchoipnoj hhcgncnlefbalaihceaongbncobmflfk bldiinacmnpomandojkiefjcbffhfoai
import { getConfig } from './config';
import { getLocalStorageKey, removeLocalStorageKey, setLocalStorageKey } from './local-storage';
import { resetContainerStyles } from './page-data';
import {
    counterStyles,
    delayMsStyles,
    speedIndicatorStyles,
    stopButtonStyles,
    tagInputContainerStyles,
    tagInputStyles
} from './page-styles';
import { getTagValue, removeTag, setTagStyles, setTagValue } from './search-tag';
import { tag } from './status-indicator';

export const autoExpandKey = 'autoExpand';
const autoScrapedCountKey = 'autoScrapedCount';
const consecutiveFastScrapedCountKey = 'consecutiveFastScrapedCount';
const speedKey = 'speed';
// tslint:disable:no-magic-numbers
const autoExpandMaxTimeMs = 1000 * 60 * 60; // 1 hour
const autoExpandDelayMs = 10000; // 10 seconds
const clickToNextPageDelayMs = 20000; // 20 seconds
const randomLargeDelayMs = 1000 * 60 * 2; // 2 minutes
const randomLargeDelayChance = 0.1;
const longerWaitDelayMs = 1200000; // 20 minutes
const maxBurstAutoScrapeCount = 75;
const countsTTL = 1000 * 60 * 60 * 1; // 1 hour
const speeds = ['1.0x', '1.5x', '2.0x', '2.5x', '3.0x', '4.0x', '5.0x'];
// tslint:enable:no-magic-numbers

let interval: NodeJS.Timeout;
let tagInputVisible = false;

function doesExtensionSupportAutoScrape() {
    const extensionId = getConfig().ExtensionId;
    return (
        extensionId === 'ddifhkkdlhheplpcknmceknjchoipnoj' ||
        extensionId === 'hhcgncnlefbalaihceaongbncobmflfk' ||
        extensionId === 'bldiinacmnpomandojkiefjcbffhfoai'
    );
}

export function isAutoExpandEnabled() {
    const enabled = doesExtensionSupportAutoScrape() && getLocalStorageKey<boolean>(autoExpandKey, false) === true;
    if (!enabled) {
        removeTag();
    }
    return enabled;
}

function updateAutoExpandTTL() {
    // as long as auto clicks are close enough in time, we keep incrementing the autoExpand key ttl to
    // now + autoExpandMaxTimeMs
    // if the search completes and is stopped for a while, the ttl will expire and the next search
    // will need to restart the autoExpand
    // ensures a search does not stop in the middle
    // AND that a new search does not start by default if long time has passed
    const expireAt = Date.now() + autoExpandMaxTimeMs;
    setLocalStorageKey<boolean>(autoExpandKey, true, expireAt);
}

export function enableAutoExpand() {
    if (doesExtensionSupportAutoScrape()) {
        removeLocalStorageKey(autoScrapedCountKey);
        removeLocalStorageKey(consecutiveFastScrapedCountKey);
        setAutoScrapedIndicator();
        showTagIndicator();
        const expireAt = Date.now() + autoExpandMaxTimeMs;
        setLocalStorageKey<boolean>(autoExpandKey, true, expireAt);
    }
}

export function getAutoScrapedInSessionCount() {
    return getLocalStorageKey<number>(autoScrapedCountKey, 0);
}

export function incrementAutoScrapedInSessionCount() {
    const count = getAutoScrapedInSessionCount();
    setLocalStorageKey<number>(autoScrapedCountKey, count + 1, Date.now() + countsTTL);
    const consecutiveCount = getLocalStorageKey<number>(consecutiveFastScrapedCountKey, 0);
    setLocalStorageKey<number>(consecutiveFastScrapedCountKey, consecutiveCount + 1, Date.now() + countsTTL);
    setAutoScrapedIndicator();
    updateAutoExpandTTL();
}

function getSpeed() {
    return getLocalStorageKey<string>(speedKey, '1.0x');
}

function getRandomTime(base: number) {
    const speed = getSpeed();
    const speedMultiplier = parseFloat(speed.replace('x', ''));
    const baseDelayMs = Math.ceil((base * (1 + Math.random())) / 2);
    const delayMs = Math.ceil(baseDelayMs / speedMultiplier);
    setDelayIndicator(delayMs);
    setAutoScrapedIndicator();
    return delayMs;
}

export function getExpandClickDelay() {
    return getRandomTime(autoExpandDelayMs);
}

export function getNextPageClickDelay() {
    const consecutiveCount = getLocalStorageKey<number>(consecutiveFastScrapedCountKey, 0);
    const randomThreshold = Math.ceil((maxBurstAutoScrapeCount * (1 + Math.random())) / 2);
    if (consecutiveCount < randomThreshold) {
        const randomChance = Math.random();
        const baseDelay = randomChance < randomLargeDelayChance ? randomLargeDelayMs : clickToNextPageDelayMs;
        return getRandomTime(baseDelay);
    } else {
        removeLocalStorageKey(consecutiveFastScrapedCountKey);
        return getRandomTime(longerWaitDelayMs);
    }
}

function setAutoScrapedIndicator() {
    const count = getAutoScrapedInSessionCount();
    const appRoot = document.getElementById('app-root');
    let countElement = appRoot?.querySelector(`div#counter`);
    if (!countElement) {
        const element = document.createElement('div');
        element.id = 'counter';
        appRoot.insertBefore(element, appRoot.firstChild);
    }
    countElement = appRoot?.querySelector(`div#counter`);
    countElement.innerHTML = `${count}`;
    countElement.setAttribute('style', `${counterStyles}`);
    showTagIndicator();
}

function handleTagClick() {
    if (tagInputVisible) {
        hideTagInputField();
        tagInputVisible = false;
    } else {
        showTagInputField();
        tagInputVisible = true;
    }
}

function showTagInputField() {
    const appRoot = document.getElementById('app-root');
    let tagInputContainer = appRoot?.querySelector(`#tag-input-container`);
    if (!tagInputContainer) {
        tagInputContainer = document.createElement('div');
        tagInputContainer.id = 'tag-input-container';
        appRoot.insertBefore(tagInputContainer, appRoot.firstChild);
        tagInputContainer.setAttribute('style', `${tagInputContainerStyles}`);
        const input = document.createElement('input');
        input.type = 'text';
        input.id = 'tag-input-field';
        input.setAttribute('style', `${tagInputStyles}`);
        input.value = getTagValue(true) ?? '';
        input.addEventListener('input', () => {
            setTagValue(input.value, true);
        });
        input.addEventListener('keydown', (event) => {
            // Check if the 'Enter' key was pressed
            // tslint:disable-next-line:no-magic-numbers
            if (event.key === 'Enter' || event.keyCode === 13) {
                handleTagClick(); // hide the tag field
            }
        });
        tagInputContainer.appendChild(input);
    }
}

function hideTagInputField() {
    const appRoot = document.getElementById('app-root');
    const tagInputContainer = appRoot?.querySelector(`#tag-input-container`);
    if (tagInputContainer) {
        tagInputContainer.remove();
    }
}

function showTagIndicator() {
    const appRoot = document.getElementById('app-root');
    let tagElement = appRoot?.querySelector(`div#tag`);
    if (!tagElement) {
        const element = document.createElement('div');
        element.id = 'tag';
        const counterElement = appRoot?.querySelector(`div#counter`);
        appRoot.insertBefore(element, counterElement);
    }
    tagElement = appRoot?.querySelector(`div#tag`);
    tagElement.innerHTML = tag;
    tagElement.removeEventListener('click', handleTagClick);
    tagElement.addEventListener('click', handleTagClick);
    setTagStyles();
}

function timeUntil(timestamp: number) {
    // Calculate the difference in milliseconds
    const now = new Date().getTime();
    const diff = timestamp - now;

    // Check if the timestamp is in the past
    if (diff < 0) {
        return '--:--';
    }
    // tslint:disable:no-magic-numbers
    // Convert milliseconds to minutes and seconds
    const minutes = Math.floor(diff / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);

    // Format minutes and seconds to have leading zeros if needed
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const secondsStr = seconds < 10 ? '0' + seconds : seconds;
    // tslint:enable:no-magic-numbers

    return minutesStr + ':' + secondsStr;
}

function setDelayIndicator(delayMs: number) {
    if (interval) {
        clearInterval(interval);
    }
    const appRoot = document.getElementById('app-root');
    let delayElement = appRoot?.querySelector(`div#delay`);
    if (!delayElement) {
        const element = document.createElement('div');
        element.id = 'delay';
        const stateElement = appRoot?.querySelector(`div#state`);
        appRoot.insertBefore(element, stateElement);
    }
    delayElement = appRoot?.querySelector(`div#delay`);
    const timestamp = Date.now() + delayMs;
    interval = setInterval(() => {
        delayElement.innerHTML = timeUntil(timestamp);
    }, 1000); // tslint:disable-line:no-magic-numbers
    delayElement.setAttribute('style', `${delayMsStyles}`);
    setSpeedIndicator();
}

function handleSpeedClick() {
    const speed = getSpeed();
    const index = speeds.indexOf(speed);
    const nextIndex = index === speeds.length - 1 ? 0 : index + 1;
    setLocalStorageKey<string>(speedKey, speeds[nextIndex], -1);
    setSpeedIndicator();
}

function setSpeedIndicator() {
    const appRoot = document.getElementById('app-root');
    let speedElement = appRoot?.querySelector(`div#speed`);
    if (!speedElement) {
        const element = document.createElement('div');
        element.id = 'speed';
        const delayElement = appRoot?.querySelector(`div#delay`);
        appRoot.insertBefore(element, delayElement);
    }
    speedElement = appRoot?.querySelector(`div#speed`);
    speedElement.innerHTML = getSpeed();
    speedElement.removeEventListener('click', handleSpeedClick);
    speedElement.addEventListener('click', handleSpeedClick);
    speedElement.setAttribute('style', `${speedIndicatorStyles}`);
    showStopButton();
}

function handleStopButtonClick() {
    removeLocalStorageKey(autoExpandKey);
    removeLocalStorageKey(autoScrapedCountKey);
    removeLocalStorageKey(consecutiveFastScrapedCountKey);
    setTagValue('', true);
    const appRoot = document.getElementById('app-root');
    const delayElement = appRoot?.querySelector(`div#delay`);
    if (delayElement) {
        delayElement.remove();
    }
    const speedElement = appRoot?.querySelector(`div#speed`);
    if (speedElement) {
        speedElement.remove();
    }
    const stopButton = appRoot?.querySelector(`div#stop-button`);
    if (stopButton) {
        stopButton.remove();
    }
    const counter = appRoot?.querySelector(`div#counter`);
    if (counter) {
        counter.remove();
    }
    const tagElement = appRoot?.querySelector(`div#tag`);
    if (tagElement) {
        tagElement.remove();
    }
    if (interval) {
        clearInterval(interval);
    }
    resetContainerStyles();
}

function showStopButton() {
    const appRoot = document.getElementById('app-root');
    let activeElement = appRoot?.querySelector(`div#stop-button`);
    if (!activeElement) {
        const element = document.createElement('div');
        element.id = 'stop-button';
        const speedElement = appRoot?.querySelector(`div#speed`);
        if (speedElement) {
            appRoot.insertBefore(element, speedElement);
        }
    }
    activeElement = appRoot?.querySelector(`div#stop-button`);
    activeElement.innerHTML = '⏹';
    activeElement.removeEventListener('click', handleStopButtonClick);
    activeElement.addEventListener('click', handleStopButtonClick);
    activeElement.setAttribute('style', `${stopButtonStyles}`);
}
