import { compressToUTF16 } from 'lz-string';
import { v4 } from 'uuid';

import { isAutoExpandEnabled } from './auto-scrape';
import { getConfig } from './config';
import { logger } from './logger';
import { getTagValue, setTagValue } from './search-tag';
import { track } from './track';

function getTagForUpload() {
    if (isAutoExpandEnabled()) {
        let tag = getTagValue(false);
        if (!tag) {
            tag = v4();
            setTagValue(tag, false);
        }
        return tag;
    } else {
        return undefined;
    }
}

export async function sendData(content: { html: string; url: string; sourceUrl: string }) {
    const config = getConfig();
    const endpoint = config.ApiEndpoint;
    const headers = {
        'Content-Type': 'application/json',
        'x-api-key': config.ApiKey
    };
    const startTime = Date.now();
    const tag = getTagForUpload();
    const body = JSON.stringify({
        html: compressToUTF16(content.html),
        sourceUrl: content.sourceUrl,
        tag,
        url: content.url
    });
    const method = 'post';
    const result = await fetch(endpoint, {
        body,
        headers,
        method
    });
    const endTime = Date.now();
    track('api-time', {
        compressed: true,
        duration: endTime - startTime,
        endpoint: config.ApiEndpoint,
        payloadSize: body.length,
        profileUrl: content.url,
        success: result.ok,
        url: content.sourceUrl
    });
    if (!result.ok) {
        logger.error(new Error('upload failed'), { event: { body, endpoint, headers, method, result } });
        throw new Error('upload failed');
    }
    return result;
}
