export const sidebarContainerStyles = `
    position: fixed;
    top: 55px;
    right: 50px;
    width: 72px;
    height: 72px;
    z-index: 140000;
`;

export const stateContainerStyles = `
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 24px;
`;

export const pendingLoadStyle = `
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 48px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    font-family:sans-serif;
`;

export const sidebarAutoScrapeStyles = `
    position: fixed;
    top: 55px;
    right: 72px;
    width: 345px;
    height: 72px;
    z-index: 140000;
`;

export const appRootAutoScrapeStyles = `
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: white;
    border-radius: 24px;
    border: thin solid #e0e0e0;
    position: relative;
`;

export const delayMsStyles = `
    font-family: sans-serif;
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const speedIndicatorStyles = `
    font-family: sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-right: thin solid #e0e0e0;
    border-left: thin solid #e0e0e0;
    width: 20px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
`;

export const stopButtonStyles = `
    font-family: sans-serif;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    width: 18px;
    padding: 0 15px;
    border-left: thin solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
`;

export const counterStyles = `
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
`;

export const tagStyles = `
    font-family: sans-serif;
    font-size: 24px;
    font-weight: bold;
    width: 40px;
    padding: 0 5px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: thin solid #e0e0e0;
    cursor: pointer;
`;

export const tagInputContainerStyles = `
    position: absolute;
    left: 56px;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    padding: 5px 30px 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const tagInputStyles = `
    border: none;
    border-bottom: 2px solid #e0e0e0;
    font-size: 18px;
    padding: 4px 2px;
    background-color: transparent;
    outline: none;
    width: 100%;
    z-index: 1000;
`;
