import { Profile, ProfileParsingErrors, RetryableParsingErrors } from 'profile-parser';
import { PublicProfile } from 'profile-parser/dist/public/types';

import { sendData } from './api';
import {
    enableAutoExpand,
    getExpandClickDelay,
    getNextPageClickDelay,
    incrementAutoScrapedInSessionCount,
    isAutoExpandEnabled
} from './auto-scrape';
import { requestElementClick, requestHtmlPendingLoadCounts, updatePageStyles } from './message-listeners';
import {
    appRootAutoScrapeStyles,
    pendingLoadStyle,
    sidebarAutoScrapeStyles,
    sidebarContainerStyles,
    stateContainerStyles
} from './page-styles';
import { parseJsonFromHtml } from './parse-check';
import { AppState, backgroundColors, icons } from './status-indicator';
import { track } from './track';

let url: string;
let html: string;
let uploadedUrl: string;
let containerElementId: string;
let pendingElementsCount: number;
let pageAutoExpandInProgress = false;
const pageLoadWaitDelayMs = 1000;
const nextPageSelector = '.skyline-pagination-link[rel="next"]';

function navigateToNextPage(immediate = false) {
    // request next page
    if (isAutoExpandEnabled()) {
        updateAutoScrapeStyles();
        incrementAutoScrapedInSessionCount();
        const delay = immediate ? 1 : getNextPageClickDelay();
        setTimeout(() => {
            // check again after delay
            if (isAutoExpandEnabled()) {
                requestElementClick({
                    clickDelayMs: 0,
                    selector: nextPageSelector
                });
            }
        }, delay);
    }
}

function updatePageData(data: { url: string; html: string; containerElementId: string }) {
    if (containerElementId !== data.containerElementId || url !== data.url) {
        pendingElementsCount = undefined;
        uploadedUrl = undefined;
        pageAutoExpandInProgress = false;
        setAppState('Pending');
        setTimeout(() => requestHtmlPendingLoadCounts(data.containerElementId, false, false), pageLoadWaitDelayMs);
    } else if (pendingElementsCount > 0) {
        requestHtmlPendingLoadCounts(data.containerElementId, false, false);
    }
    url = data.url;
    html = data.html;
    containerElementId = data.containerElementId;
}

function updatePendingElementsCount(data: {
    containerElementId: string;
    html: string;
    pendingLoad: Array<{ tag: string; text: string }>;
    url: string;
}) {
    html = data.html;
    url = data.url;
    if (containerElementId !== data.containerElementId || url !== data.url) {
        setAppState('Pending');
        uploadedUrl = undefined;
        pageAutoExpandInProgress = false;
        pendingElementsCount = undefined;
        containerElementId = data.containerElementId;
        setTimeout(() => requestHtmlPendingLoadCounts(data.containerElementId, false, false), pageLoadWaitDelayMs);
    } else {
        pendingElementsCount = data.pendingLoad.length;
        if (pendingElementsCount === undefined) {
            setAppState('Pending');
        } else if (data.pendingLoad.length === 0) {
            setAppState('Checking');
            track('profile-content-loaded', { url });
            // check if we can parse the profile
            let parsed: Profile | PublicProfile;
            try {
                parsed = parseJsonFromHtml(html, url);
            } catch (e) {
                if (RetryableParsingErrors.includes(e?.message)) {
                    setAppState('Pending');
                    setTimeout(
                        () => requestHtmlPendingLoadCounts(data.containerElementId, false, false),
                        pageLoadWaitDelayMs
                    );
                } else {
                    setAppState('Error');
                    track('html-to-json-parsing-error', {
                        error: e?.message ?? 'unknown',
                        url
                    });
                    if (e?.message === ProfileParsingErrors.outOfNetworkProfile) {
                        navigateToNextPage(true);
                    } else {
                        // do not auto navigate to next page if the error is not retryable
                        // linkedin might have blocked the profile and needs manual intervention
                    }
                }
            }
            if (parsed) {
                if (uploadedUrl === parsed.publicLink) {
                    setAppState('Complete');
                    return;
                } else {
                    uploadedUrl = parsed.publicLink;
                    setAppState('Uploading');
                    track('upload-start', {
                        kind: 'html',
                        profileUrl: parsed.publicLink,
                        url
                    });
                    sendData({ html, sourceUrl: url, url: parsed.publicLink })
                        .then(() => {
                            setAppState('Complete');
                            track('upload-success', {
                                kind: 'html',
                                profileUrl: parsed.publicLink,
                                url
                            });
                        })
                        .catch(() => {
                            setAppState('Error');
                            track('upload-error', {
                                kind: 'html',
                                profileUrl: parsed.publicLink,
                                url
                            });
                        })
                        .then(() => {
                            navigateToNextPage();
                        });
                }
            }
        } else {
            setAppState('NeedsExpand');
            track('profile-content-pending', { url, pendingElementsCount });
        }
    }
}

function getPageData() {
    return { url, html, containerElementId, pendingElementsCount };
}

function setAppState(state: AppState) {
    const appRoot = document.getElementById('app-root');
    let stateElement = appRoot?.querySelector(`div#state`);
    if (!stateElement) {
        appRoot?.insertAdjacentHTML('beforeend', `<div id="state"/>`);
    }
    stateElement = appRoot?.querySelector(`div#state`);
    if (stateElement) {
        stateElement.setAttribute('style', `${stateContainerStyles}background-color:${backgroundColors[state]}`);
        if (pendingElementsCount === 0 || pendingElementsCount === undefined) {
            stateElement.innerHTML = icons[state];
        } else {
            stateElement.innerHTML = `<div class="pending-load-count" style="${pendingLoadStyle}">${pendingElementsCount}</div>`;
            const pendingCountElement = stateElement.querySelector(`div.pending-load-count`);
            pendingCountElement.addEventListener('click', () => {
                enableAutoExpand();
                requestHtmlPendingLoadCounts(containerElementId, true, true);
                pendingCountElement.setAttribute('style', `${pendingLoadStyle}opacity:0.5;cursor:default;`);
            });
            if (!pageAutoExpandInProgress && isAutoExpandEnabled()) {
                pageAutoExpandInProgress = true;
                updateAutoScrapeStyles();
                const delay = getExpandClickDelay();
                setTimeout(() => {
                    if (isAutoExpandEnabled()) {
                        requestHtmlPendingLoadCounts(containerElementId, true, true);
                    }
                }, delay);
            }
        }
    }
}

function updateAutoScrapeStyles() {
    if (isAutoExpandEnabled()) {
        updatePageStyles([{ selector: `#${containerElementId}`, styles: sidebarAutoScrapeStyles }]);
        const appRoot = document.getElementById('app-root');
        appRoot?.setAttribute('style', `${appRootAutoScrapeStyles}`);
    }
}

function resetContainerStyles() {
    updatePageStyles([{ selector: `#${containerElementId}`, styles: sidebarContainerStyles }]);
    const appRoot = document.getElementById('app-root');
    appRoot?.setAttribute('style', stateContainerStyles);
}

function checkAutoScrapeProgress() {
    const checkInterval = 1000; // Check every 1000 milliseconds (1 second)
    const maxStuckTime = 300000; // 5 minutes
    let firstDetectedTime: number = null; // Time when '--:--' was first detected

    setInterval(() => {
        const div = document.getElementById('delay');

        // Check if the div exists and its content is '--:--'
        if (div && div.innerHTML === '--:--') {
            // If firstDetectedTime has not been set, set it to the current time
            if (!firstDetectedTime) {
                firstDetectedTime = Date.now();
            } else if (Date.now() - firstDetectedTime > maxStuckTime) {
                // If it has been '--:--' for more than 5 minutes, try to continue the process
                track('auto-scrape-stuck', { url, durationMs: Date.now() - firstDetectedTime });
                if (pendingElementsCount > 0) {
                    requestHtmlPendingLoadCounts(containerElementId, true, true);
                } else {
                    navigateToNextPage(true);
                }
                // Instead of stopping the check, reset firstDetectedTime to start a new 5-minute period
                firstDetectedTime = Date.now(); // Reset the timer to start the 5-minute count again
            }
        } else {
            // If the condition does not match, reset firstDetectedTime
            firstDetectedTime = null;
        }
    }, checkInterval);
}

export {
    updatePageData,
    getPageData,
    updatePendingElementsCount,
    setAppState,
    checkAutoScrapeProgress,
    resetContainerStyles
};
