import { tagSetByUserKey, tagValueKey } from './search-tag';

export const getLocalStorageKey = <T>(key: string, defaultVal: T): T => {
    try {
        const val = localStorage.getItem(key);
        const { value, expireAt } = JSON.parse(val);
        if (value !== undefined && value !== null && (expireAt === -1 || expireAt > Date.now())) {
            return value;
        } else {
            localStorage.removeItem(key);
            return defaultVal;
        }
    } catch (err) {
        localStorage.removeItem(key);
        return defaultVal;
    }
};

export const setLocalStorageKey = <T>(key: string, value: T, expireAt: number) => {
    localStorage.setItem(key, JSON.stringify({ value, expireAt }));
};

export const removeLocalStorageKey = (key: string) => localStorage.removeItem(key);

export const getKeyExpirationTs = (key: string) => {
    try {
        const val = localStorage.getItem(key);
        const { expireAt } = JSON.parse(val);
        return expireAt;
    } catch (err) {
        localStorage.removeItem(key);
        return undefined;
    }
};

export const pruneExpiredKeys = () => {
    const pruneExcludedKeys = [tagValueKey, tagSetByUserKey];
    const data = { ...localStorage };
    for (const key of Object.keys(data)) {
        if (!pruneExcludedKeys.includes(key)) {
            // fetching will prune the expired keys
            getLocalStorageKey(key, null);
        }
    }
};
