// cspell:disable
declare const APP_VERSION: string;

type Version = 'dev' | 'prod-internal' | 'prod-external' | 'prod-external-vc';

let version: Version;

const dev = {
    ApiEndpoint: 'https://api.lambda-usw2.gobopo.com/v1/enhance-profile-dev',
    ApiKey: 'xffZ5ke5Sc2kcMVWOetWayFtd8CmneJaDXIq5Df1',
    AppVersion: APP_VERSION,
    Env: 'development',
    ExtensionId: 'hhcgncnlefbalaihceaongbncobmflfk'
};

const prodInternal = {
    ApiEndpoint: 'https://api.lambda-usw2.gobopo.com/v1/enhance-profile',
    ApiKey: 'LvXZiGwAOz9D4OSNiL9MS6DfuQdcfk1L1aDwTT17',
    AppVersion: APP_VERSION,
    Env: 'production',
    ExtensionId: 'ddifhkkdlhheplpcknmceknjchoipnoj'
};

const prodExternal = {
    ApiEndpoint: 'https://api.avalsat.com/v2/enhance',
    ApiKey: 'tLiFv7VmQy6ijeDQp1zQu7LNVfcf6aNqAwomQEjc',
    AppVersion: APP_VERSION,
    Env: 'production',
    ExtensionId: 'mgkblcaeffcmmefkcnhogcanpaabcmgg'
};

const prodExternalVc = {
    ApiEndpoint: 'https://api.lambda-usw2.gobopo.com/v1/enhance-profile-vc',
    ApiKey: 'k4TtEyu4cq9s9icmWPyBU17UmRtzmr6NWV1cHIs7',
    AppVersion: APP_VERSION,
    Env: 'production',
    ExtensionId: 'bldiinacmnpomandojkiefjcbffhfoai'
};

function getConfig() {
    if (!version) {
        throw new Error('version not set');
    }
    switch (version) {
        case 'dev':
            return dev;
        case 'prod-internal':
            return prodInternal;
        case 'prod-external':
            return prodExternal;
        case 'prod-external-vc':
            return prodExternalVc;
        default:
            throw new Error(`invalid version: ${version}`);
    }
}

function setVersion(path: string) {
    const parsed = path.match(/^\/dev/) ? 'dev' : (path.replace(/^\/|\/$/g, '') as Version);
    if (['dev', 'prod-internal', 'prod-external', 'prod-external-vc'].indexOf(parsed) === -1) {
        throw new Error(`invalid version: ${parsed}`);
    }
    version = parsed;
}

export { getConfig, setVersion };
