import { parseProfile } from 'profile-parser';

const parser: DOMParser = new DOMParser();

function getDocument(htmlString: string) {
    const doc: Document = parser.parseFromString(htmlString, 'text/html');
    return doc;
}

export function parseJsonFromHtml(html: string, url: string) {
    const result = parseProfile(getDocument, html, url);
    return result;
}
