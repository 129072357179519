type AppState = 'Pending' | 'NeedsExpand' | 'Uploading' | 'Complete' | 'Error' | 'Checking';

const checking = `<?xml version="1.0" encoding="utf-8"?>
<svg fill="#ffffff" width="36px" height="36px" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <circle cx="17.5" cy="12" r="1.5"/>
  <circle cx="12" cy="12" r="1.5"/>
  <circle cx="6.5" cy="12" r="1.5"/>
</svg>`;

const uploading = `<?xml version="1.0" encoding="utf-8"?>
<svg fill="#ffffff" width="36px" height="36px" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.364 8.95l-5.657-5.657a1 1 0 0 0-1.414 0L5.636 8.95a1 1 0 0 0 1.414 1.414L11 6.414V20a1 1 0 0 0 2 0V6.414l3.95 3.95a1 1 0 0 0 1.414-1.414z"/>
</svg>`;

const error = `<?xml version="1.0" encoding="utf-8"?>
<svg fill="#ffffff" width="36px" height="36px" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.414 12l4.95-4.95a1 1 0 0 0-1.414-1.414L12 10.586l-4.95-4.95A1 1 0 0 0 5.636 7.05l4.95 4.95-4.95 4.95a1 1 0 0 0 1.414 1.414l4.95-4.95 4.95 4.95a1 1 0 0 0 1.414-1.414z"/>
</svg>`;

const done = `<?xml version="1.0" encoding="utf-8"?>
<svg fill="#ffffff" width="36px" height="36px" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.172 18.657a1 1 0 0 1-.707-.293l-5.657-5.657a1 1 0 0 1 1.414-1.414l4.95 4.95L19.778 5.636a1 1 0 0 1 1.414 1.414L9.879 18.364a1 1 0 0 1-.707.293z"/>
</svg>`;

const loading = `<?xml version="1.0" encoding="utf-8"?>
<svg fill="#ffffff" width="36px" height="36px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 21a9 9 0 1 1 9-9 9.01 9.01 0 0 1-9 9zm0-16a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z"/>
    <path d="M15.03 14.75a1 1 0 0 1-.5-.134l-3.03-1.75A1 1 0 0 1 11 12V7.5a1 1 0 0 1 2 0v3.923l2.531 1.461a1 1 0 0 1-.501 1.866z"/>
</svg>`;

const tag = `<?xml version="1.0" encoding="utf-8"?>
<svg class="svg-icon" style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <path d="M234.666667 298.666667A64 64 0 0 1 170.666667 234.666667 64 64 0 0 1 234.666667 170.666667 64 64 0 0 1 298.666667 234.666667 64 64 0 0 1 234.666667 298.666667m678.826666 195.413333l-384-384C514.133333 94.72 492.8 85.333333 469.333333 85.333333H170.666667c-47.36 0-85.333333 37.973333-85.333334 85.333334v298.666666c0 23.466667 9.386667 44.8 25.173334 60.16l383.573333 384c15.786667 15.36 37.12 25.173333 60.586667 25.173334 23.466667 0 44.8-9.813333 60.16-25.173334l298.666666-298.666666c15.786667-15.36 25.173333-36.693333 25.173334-60.16 0-23.893333-9.813333-45.226667-25.173334-60.586667z" fill="" />
</svg>`;

const icons: Partial<Record<AppState, string>> = {
    Checking: checking,
    Complete: done,
    Error: error,
    Pending: loading,
    Uploading: uploading
};

const backgroundColors: Record<AppState, string> = {
    Checking: '#FFC107',
    Complete: '#4CAF50',
    Error: '#F44336',
    NeedsExpand: '#2196F3',
    Pending: '#2196F3',
    Uploading: '#2196F3'
};

export { AppState, icons, backgroundColors, tag };
