import { autoExpandKey } from './auto-scrape';
import { getKeyExpirationTs } from './local-storage';
import { tagStyles } from './page-styles';
import { backgroundColors } from './status-indicator';

export const tagValueKey = 'tagValue';
export const tagSetByUserKey = 'tagSetByUser';

function focusTagInput() {
    const input: HTMLInputElement = document.getElementById('tag-input-field') as HTMLInputElement;
    if (input) {
        input.focus();
        // This ensures the cursor is placed at the end of the input text
        const val = input.value; // Store the value of the input
        input.value = ''; // Clear the input's value
        input.value = val; // Re-assign the value to the input
    }
}

export function setupAutoTagInputFocus() {
    // Create an observer instance linked to a callback function
    // The callback will be executed when mutations are observed
    const observer = new MutationObserver((mutationsList) => {
        // Use traditional 'for loops' for IE 11
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                // If children are added or removed, check for the input and focus
                focusTagInput();
            }
        }
    });
    observer.observe(document.body, { childList: true, subtree: true });
}

export function setTagStyles() {
    const appRoot = document.getElementById('app-root');
    const tagElement = appRoot?.querySelector(`div#tag`);
    if (tagElement) {
        const tagStyle =
            localStorage.getItem(tagSetByUserKey) === 'true'
                ? `${tagStyles}color: ${backgroundColors.Pending};`
                : tagStyles;
        tagElement.setAttribute('style', tagStyle);
    }
}

export function getTagValue(onlyIfSetByUser: boolean) {
    const expirationTs = getKeyExpirationTs(autoExpandKey);
    if (expirationTs === undefined || expirationTs < Date.now()) {
        localStorage.removeItem(tagValueKey);
        localStorage.removeItem(tagSetByUserKey);
        return undefined;
    }
    const localStorageValue = localStorage.getItem(tagValueKey) ?? '';
    if (!onlyIfSetByUser) {
        return localStorageValue;
    } else {
        const tagSetByUser = localStorage.getItem(tagSetByUserKey) === 'true';
        return tagSetByUser ? localStorageValue : '';
    }
}

export function setTagValue(val: string, setByUser: boolean) {
    const value = val?.trim() ?? '';
    if (value.length > 0) {
        localStorage.setItem(tagValueKey, value);
    } else {
        localStorage.removeItem(tagValueKey);
    }
    if (setByUser && value) {
        localStorage.setItem(tagSetByUserKey, 'true');
    } else {
        localStorage.removeItem(tagSetByUserKey);
    }
    setTagStyles();
}

export function removeTag() {
    localStorage.removeItem(tagValueKey);
    localStorage.removeItem(tagSetByUserKey);
    setTagStyles();
}
