import { v4 } from 'uuid';

import { ExtensionMessage } from 'shared/extension/messaging';

import { getConfig } from './config';
import { logger } from './logger';
import { updatePageData, updatePendingElementsCount } from './page-data';
import { sidebarContainerStyles } from './page-styles';

let containerStyled = false;

function sendToContentScript(message: ExtensionMessage) {
    const config = getConfig();
    const targetOrigin = `chrome-extension://${config.ExtensionId}`;
    window.parent.postMessage(message, targetOrigin);
}

function isTrustedMessage(e: MessageEvent) {
    const config = getConfig();
    const targetOrigin = `chrome-extension://${config.ExtensionId}`;
    return e.origin === targetOrigin;
}

function requestPageData() {
    sendToContentScript({ type: 'pageDataRequest', messageId: v4(), sender: 'remote' });
}

function updatePageStyles(
    updates: Array<{
        selector: string;
        styles: string;
    }>
) {
    sendToContentScript({
        messageId: v4(),
        payload: { updates },
        sender: 'remote',
        type: 'domElementsStyleUpdateRequest'
    });
}

function requestPendingElements(payload: {
    containerElementId: string;
    options: {
        targetElementSelector: string;
        includedTreeSelectors: string[];
        excludedTreeSelectors: string[];
        scrollOptions: ScrollIntoViewOptions;
        autoClick: boolean;
        autoClickDelayMs: number;
    };
}) {
    sendToContentScript({ type: 'elementsCountCheck', messageId: v4(), sender: 'remote', payload });
}

function requestElementClick(payload: {
    selector: string;
    scrollOptions?: ScrollIntoViewOptions;
    clickDelayMs?: number;
}) {
    sendToContentScript({ type: 'clickElement', messageId: v4(), sender: 'remote', payload });
}

function handleWindowMessage(event: MessageEvent) {
    // console.log(`remote iframe: handleWindowMessage`, { event });
    if (!isTrustedMessage(event)) {
        logger.debug('remote iframe: event not from a trusted source, ignoring', { event });
    } else {
        const data: ExtensionMessage = event?.data;
        switch (data?.type) {
            case 'pageDataUpdate':
                handlePageDataChange(data.payload);
                break;
            case 'elementsCheckResponse':
                handleElementsCheckResponse({
                    containerElementId: data.payload.containerElementId,
                    html: data.payload.html,
                    pendingLoad: data.payload.elements,
                    url: data.payload.url
                });
                break;
            default:
                logger.info('remote iframe: unhandled message', { event });
                break;
        }
    }
}

function addWindowMessageListener() {
    window.addEventListener('message', handleWindowMessage);
}

// handle updates about profile content - messages from chrome extension
function handlePageDataChange(tabData: { html: string; url: string; containerElementId: string }) {
    if (tabData) {
        updatePageData(tabData);
        if (!containerStyled) {
            updatePageStyles([{ selector: `#${tabData.containerElementId}`, styles: sidebarContainerStyles }]);
            containerStyled = true;
        }
    }
}

// listen for content script updates about pending sections that need to be expanded
function handleElementsCheckResponse(message: {
    containerElementId: string;
    html: string;
    pendingLoad: Array<{ tag: string; text: string }>;
    url: string;
}) {
    updatePendingElementsCount(message);
}

function setupExtensionListeners() {
    addWindowMessageListener();
    requestPageData();
}

// elements that must be expanded before attempting profile parsing
const PROFILE_HTML_EXPAND_SELECTORS = [
    'button[data-test-expand-more-lower-button]',
    'a.lt-line-clamp__more',
    'button[data-test-decorated-line-clamp-see-more-button]'
];

// target element containing the profile data
const PROFILE_ELEMENT_SELECTOR = '#profile-container';

// how to scroll a pending element in to view - set null to disable
const SCROLL_INTO_VIEW_OPTIONS: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center', inline: 'center' };

// selectors to exclude within the DOM tree for checking on needs to be expanded
const PROFILE_HTML_EXPAND_EXCLUDED_SELECTORS = ['.shared-connections__list'];

// delay between clicks when auto expanding
const autoClickDelayMs = 500;

const htmlLoadCheckMessageBody = {
    excludedTreeSelectors: PROFILE_HTML_EXPAND_EXCLUDED_SELECTORS,
    includedTreeSelectors: PROFILE_HTML_EXPAND_SELECTORS,
    targetElementSelector: PROFILE_ELEMENT_SELECTOR
};

// send a message requesting pending html sections that need to be expanded
function requestHtmlPendingLoadCounts(containerElementId: string, useAutoScroll: boolean, autoClick: boolean) {
    const scrollOptions = useAutoScroll ? SCROLL_INTO_VIEW_OPTIONS : null;
    requestPendingElements({
        containerElementId,
        options: { ...htmlLoadCheckMessageBody, scrollOptions, autoClick, autoClickDelayMs }
    });
}

export { setupExtensionListeners, requestHtmlPendingLoadCounts, requestElementClick, updatePageStyles };
