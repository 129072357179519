declare const APP_ENV: string;

import _ from 'lodash';

import { mixpanel } from './mixpanel';

const MixpanelToken = '5c1e6eb623a7f0a5c920157d69c02d4a';

export const track = (
    eventName: string,
    params?: {
        [index: string]: any;
    }
) => {
    if (APP_ENV === 'production') {
        mixpanel.track(eventName, params);
    } else {
        // tslint:disable:no-console
        const secondary = 'color: rgba(255, 255, 255, 0.4); font-weight: normal';
        const em = 'font-weight: bold';
        const ts = new Date().toLocaleTimeString();
        console.groupCollapsed('%c track %c%s %c@ %s', secondary, em, eventName, secondary, ts);
        for (const key of _.sortBy(Object.keys(params))) {
            const val = params[key];
            if (val) {
                console.log(' %c%s %c%s', secondary, key, em, val);
            }
        }
        console.groupEnd();
        // tslint:enable:no-console
    }
};

export const initializeMixpanel = () => {
    if (APP_ENV === 'production') {
        mixpanel.init(MixpanelToken);
    }
};
