declare const APP_ENV: string;
declare const APP_VERSION: string;

declare let Bugsnag: any;

const loadExternalJS = (id: string, url: string, onload: () => void) => {
    const doc = document;
    if (doc.getElementById(id)) {
        return;
    }
    const scriptElement = doc.getElementsByTagName('script')[0];
    const js = doc.createElement<'script'>('script');
    js.id = id;
    js.onload = onload;
    js.src = url;
    scriptElement.parentNode.insertBefore(js, scriptElement);
};

const bugSnagAPI = 'https://d2wy8f7a9ursnm.cloudfront.net/bugsnag-3.min.js';
const apiKey = 'ec4eb10104534cb40ab7f85526f922c1';

export const initBugSnag = () => {
    loadExternalJS('bugsnag-script', bugSnagAPI, () => {
        Bugsnag.apiKey = apiKey;
        Bugsnag.appVersion = APP_VERSION;
        Bugsnag.user = {}; // TODO: add user
    });
};

export const notifyBugSnag = (message: string, metaData: any, severity = 'error') => {
    if (APP_ENV === 'production') {
        Bugsnag.notifyException(new Error(message), { metaData, severity });
    } else {
        // tslint:disable-next-line:no-console
        console.error(message, metaData, severity);
    }
};

if (APP_ENV === 'production') {
    initBugSnag();
}
